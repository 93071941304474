import { ClassValue, clsx } from "clsx";
import dayjs, { Dayjs } from "dayjs";
import { NON_BREAKING_SPACE, SALES_CALL_LINK, SPACE } from "lib/constants";
import { customAlphabet } from "nanoid";
import shajs from "sha.js";
import { twMerge } from "tailwind-merge";

export interface ObjectLiteral {
  [key: string]: any;
}

export function isEmpty(obj: Object) {
  return Object.keys(obj).length === 0;
}

export function clamp(number: number, { min, max }: { min: number; max: number }): number {
  return Math.max(min, Math.min(number, max));
}

export function classNames(...classes: (string | undefined | null | boolean)[]) {
  return classes.filter(Boolean).join(" ");
}

// Don't use this for now as twMerge doesn't understand our tailwind.config.js
// See https://twitter.com/shadcn/status/1614692419039105024
// and https://github.com/dcastil/tailwind-merge/blob/v1.9.1/docs/features.md
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export enum ObjectTypes {
  Message = "Message",
  Datum = "Datum",
  Placeholder = "Placeholder",
}

// Custom alphabet for nanoids, excludes unaesthetic _ and -.
const nanoid = customAlphabet("1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ", 12);

/**
 * Generate an ID for an object on the frontend.
 *
 * Currently these IDs are solely for the the client to keep track of objects,
 * and the backend is the canonical generator of IDs used in the database.
 *
 * The prefix is just for slightly nicer DX.
 *
 * @param objectType
 * @returns
 */
export function generateId(
  objectType: "message" | "datum" | "call" | "editor_session" | "placeholder" = "placeholder",
) {
  const id = nanoid();

  switch (objectType) {
    case "datum":
      return `datum_${id}`;
    case "message":
      return `msg_${id}`;
    case "editor_session":
      return `editor_${id}`;
    case "call":
      // Tool call
      return `call_${id}`;
    case "placeholder":
    default:
      return id;
  }
}

interface ConditionalWrapperProps {
  condition: boolean;
  wrapper: (children: JSX.Element) => JSX.Element;
  children: JSX.Element;
}

export const ConditionalWrapper = ({ condition, wrapper, children }: ConditionalWrapperProps) =>
  condition ? wrapper(children) : children;

// From https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/flat
// (newer JS so not in all browser environments yet)
export function flattenArray<T>(array: T[]) {
  return array.reduce((accumulator, value) => accumulator.concat(value), [] as T[]);
}

export function pluralise(number?: number) {
  return number === 1 ? "" : "s";
}

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

/**
 * Set a maximum timeout for a promise
 *
 * @param promise The promise to wrap
 * @param maxTime The maximum time in milliseconds to wait for promise to resolve
 * @returns The result of the promise, or a string if the promise timed out
 */
export const withTimeout = async <T>(promise: Promise<T>, maxTime: number): Promise<T | string> => {
  const timeoutPromise = new Promise<string>((resolve) =>
    setTimeout(() => resolve(`The task took longer than ${maxTime}ms`), maxTime),
  );

  return Promise.race([promise, timeoutPromise]);
};

export const log = console.log.bind(console);

export function stringify(object: Object): string {
  return JSON.stringify(object, null, 2);
}

interface Colors {
  backgroundColor: string;
  color: string;
  borderColor: string;
}

export const changeHSLAAlpha = (color: string, alpha: number): string => {
  return color.substr(0, color.lastIndexOf("1")) + `${alpha})`;
};

// Note object.assign() will only go one level deep.
// See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/assign#Deep_Clone
export function deepClone(object: any): any {
  return JSON.parse(JSON.stringify(object));
}

export function getRandomString(length: number = 8) {
  return Math.random().toString(16).substr(2, length);
}

export function isOnlyWhitespace(string: string) {
  return string.length && !string.trim().length;
}

export function hasLeadingOrTrailingWhitespace(string: string) {
  return string.trim().length !== string.length;
}

export function cleanAnnotationText(annotationText: string | null | undefined) {
  if (!annotationText || isOnlyWhitespace(annotationText)) {
    log("String is empty spaces");
    annotationText = NON_BREAKING_SPACE;
  } else if (hasLeadingOrTrailingWhitespace(annotationText)) {
    annotationText = annotationText.replace(SPACE, NON_BREAKING_SPACE);
  }
  return annotationText;
}

export function isParamInUrl(param: string, window?: Window): boolean {
  if (!window) {
    return false;
  }
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.has(param);
}

export async function asyncForEach<T>(array: T[], iterator: (item: T, index: number, array: T[]) => Promise<void>) {
  for (let index = 0; index < array.length; index++) {
    await iterator(array[index], index, array);
  }
}

export const isPropertyDuplicated = (property: string, itemToCheck: ObjectLiteral, items: Array<ObjectLiteral>) => {
  return (
    items.filter((item) => {
      return itemToCheck[property] === item[property];
    }).length > 1
  );
};

const SI_SYMBOLS = ["", "K", "M", "G", "T", "P", "E"];

export const formatNumberSI = (number: number): string => {
  if (number === 0) {
    return `${number}`;
  }

  const tier = Math.trunc(Math.log10(Math.abs(number)) / 3);
  if (tier === 0) return `${number}`;

  const suffix = SI_SYMBOLS[tier];
  const scaled = number / Math.pow(10, tier * 3);

  return scaled.toFixed(1) + suffix;
};

export const downloadFile = (data: BlobPart, fileName: string, type: string = "application/pdf"): void => {
  // Hack required to keep filename.
  // Otherwise this would have been preferable:
  //   const file = new File([data], fileName, { type: "application/pdf" });
  //   const fileURL = URL.createObjectURL(file);
  //   window.open(fileURL);
  // See https://stackoverflow.com/a/60378502
  const a = document.createElement("a");
  a.href = URL.createObjectURL(new Blob([data], { type }));
  a.setAttribute("download", fileName);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const getTimestampForFileName = (): string => {
  return dayjs().format("YYYY-MM-DD-HH-mm-ss");
};

export const getFileNameFromS3Path = (path: string): string | undefined => path.split("\\").pop()?.split("/").pop();

export const handleBookADemo = () => {
  // Intercom may be blocked by adblockers
  if (!!window.Intercom) {
    Intercom("show");
  } else {
    window.open(SALES_CALL_LINK, "_blank");
  }
};

export const isNotNullOrUndefined = <T>(value: T | null | undefined): value is T => {
  return value !== null && value !== undefined;
};

// TODO - Dedup this with compareDates if reasonable
export const dateComparator = (a: Dayjs | undefined | null, b: Dayjs | undefined | null) => {
  if (!a && !b) return 0;
  if (!a) return -1;
  if (!b) return 1;
  return a.valueOf() - b.valueOf();
};

import { Dialog, FocusTrap, Transition } from "@headlessui/react";
import Button from "@library/Button";
import { ConfirmOptions } from "context/useConfirm";
import React, { Fragment, useRef } from "react";

interface Props {
  options: ConfirmOptions;
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

export const ConfirmationDialog = ({ options, open, onCancel, onConfirm }: Props) => {
  const confirmButtonRef = useRef<HTMLButtonElement | HTMLAnchorElement>(null);

  return (
    <Transition appear show={open} as={Fragment}>
      {/* Antd drawer has z-index of a 1000 🤦‍♂️ */}
      <Dialog as="div" className="pointer-events-auto fixed inset-0 z-[1010] overflow-y-auto" onClose={onCancel}>
        {/*  Focus the confirm button on open. I've done a little bit of 
        research and I think this OK from a UX perspective. */}
        <FocusTrap
          initialFocus={confirmButtonRef}
          onKeyDown={(e) => {
            if (e.key === "Escape") {
              onCancel();
            }
          }}
        >
          <div className="min-h-screen px-16 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-100"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black/20" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-100"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-100"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-lg transform overflow-hidden rounded-lg border bg-white p-32 text-left align-middle shadow-modal transition-all">
                <Dialog.Title className="mb-8 text-16-24 font-medium text-text-base-1">{options.title}</Dialog.Title>
                {options.description && (
                  <Dialog.Description className="text-14-20 text-text-base-2">{options.description}</Dialog.Description>
                )}
                {options.content && (
                  <div className="mt-12">
                    <p className="text-14-20 text-text-base-2">{options.content}</p>
                  </div>
                )}

                <div className="-mb-12 mt-16 flex justify-end gap-10">
                  {!options.noCancel && (
                    <>
                      <Button tabIndex={2} onClick={onCancel} {...options.cancelButtonProps}>
                        {options.cancellationText}
                      </Button>
                    </>
                  )}
                  <Button tabIndex={1} onClick={onConfirm} ref={confirmButtonRef} {...options.confirmButtonProps}>
                    {options.confirmationText}
                  </Button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </FocusTrap>
      </Dialog>
    </Transition>
  );
};

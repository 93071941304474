import { InlineLink } from "./InlineLink";

export const MessageUs = () => (
  <InlineLink
    href="#intercom"
    onClick={(e) => {
      e.preventDefault();
      if (typeof Intercom !== "undefined") {
        Intercom("show");
      } else {
        window.open("mailto:support@humanloop.com");
      }
    }}
  >
    message us
  </InlineLink>
);

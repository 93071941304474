import { FilePage } from "@/lib/path-utils";
import React, { createContext, useContext, useState } from "react";

interface TabContextProps {
  /** Last selected tab in the top nav ('editor', 'logs' etc.)
   * This maintains the last user selected page, so that when switching between files,
   * we can remember which tab type (editor/logs/etc) the user was viewing.
   * @example If a user is viewing logs for file A, then switches to file B,
   * we'll automatically show logs for file B rather than defaulting to the editor. */
  lastSelectedTab: FilePage | undefined;
  setLastSelectedTab: React.Dispatch<React.SetStateAction<FilePage | undefined>>;
}

const TabContext = createContext<TabContextProps | undefined>(undefined);

export const TabContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [lastSelectedTab, setLastSelectedTab] = useState<FilePage | undefined>(undefined);

  return (
    <TabContext.Provider
      value={{
        lastSelectedTab,
        setLastSelectedTab,
      }}
    >
      {children}
    </TabContext.Provider>
  );
};

export const useTabContext = () => {
  const context = useContext(TabContext);
  if (context === undefined) {
    throw new Error("useTabContext must be used within a TabContextProvider");
  }
  return context;
};

// A context to manage whether the user has dismissed the various tips throughout the app.
// This is used for tourtips, inline tips, and even the welcome modals.

import { createContext, useContext, useState, useEffect, useCallback, useMemo } from "react";
import { useAuth } from "@/lib/use-auth";
import { updateUser } from "@/services/users.service";

// Give each tip a unique ID.
// Each one will be stored against the user to track whether they have seen it.
// Hyphen-case preferred for future IDs as its more legible.
export const tourtipIds = [
  "hasDismissedWelcomeModal",
  "hasDismissedGettingStartedBanner",
  "hasDismissedFlowIntroModal",
  "has-logs-see-logs-tab",
] as const;

export type TourtipId = (typeof tourtipIds)[number];

// Undefined assumes the tip has not been shown yet.
// Active means it is it has been triggered but not yet dismissed, i.e. 'pending' and maybe visible if the user is on the right page.
// Dismissed means it has been dismissed by the user.
export type TourtipState = undefined | "active" | "dismissed";

interface TourtipContextValue {
  tipsReady: boolean;
  tipStates: Record<TourtipId, TourtipState>;
  triggerTip: (tipId: TourtipId) => void;
  dismissTip: (tipId: TourtipId) => Promise<void>;
  resetTip: (tipId: TourtipId) => Promise<void>;
  isTipActive: (tipId: TourtipId) => boolean | undefined;
  isTipDismissed: (tipId: TourtipId) => boolean | undefined;
}

const TourtipContext = createContext<TourtipContextValue | null>(null);

export function TourtipProvider({ children }: { children: React.ReactNode }) {
  const { user, mutate, loading: isLoading } = useAuth();
  const [activeTips, setActiveTips] = useState<Set<TourtipId>>(new Set());

  // Initialize dismissed tips from user UI state
  // A tip is considered dismissed if it exists in ui_state and is set to true
  const updateTipState = async (tipId: TourtipId, value: boolean | undefined) => {
    const updatedUserUIState = {
      ...user?.ui_state,
      [tipId]: value,
    };

    setActiveTips((prev) => {
      const next = new Set(prev);
      next.delete(tipId);
      return next;
    });

    // Optimistic update
    mutate((currentUser) => (currentUser ? { ...currentUser, ui_state: updatedUserUIState } : currentUser), false);

    try {
      await updateUser({ ui_state: updatedUserUIState });
      // Revalidate to ensure our optimistic update was correct
      mutate();
    } catch (error) {
      // If the update fails, rollback the optimistic update
      mutate();
      console.error(`Failed to ${value ? "dismiss" : "reset"} tip:`, error);
    }
  };

  const dismissedTips = useMemo(
    () =>
      new Set(
        Object.entries(user?.ui_state || {})
          .filter(([key, value]) => tourtipIds.includes(key as TourtipId) && value === true)
          .map(([key]) => key as TourtipId),
      ),
    [user],
  );

  const triggerTip = (tipId: TourtipId) => {
    if (!isLoading && !dismissedTips.has(tipId)) {
      setActiveTips((prev) => new Set(prev).add(tipId));
    }
  };
  const dismissTip = (tipId: TourtipId) => updateTipState(tipId, true);
  const resetTip = (tipId: TourtipId) => updateTipState(tipId, undefined);

  // Current state for all tips
  const tipStates: Record<TourtipId, TourtipState> = Object.fromEntries(
    tourtipIds.map((tipId) => [
      tipId,
      dismissedTips.has(tipId) ? "dismissed" : activeTips.has(tipId) ? "active" : undefined,
    ]),
  ) as Record<TourtipId, TourtipState>;

  return (
    <TourtipContext.Provider
      value={{
        tipsReady: !isLoading,
        tipStates,
        triggerTip,
        dismissTip,
        resetTip,
        isTipActive: (tipId) => (isLoading ? undefined : activeTips.has(tipId)),
        isTipDismissed: (tipId) => (isLoading ? undefined : dismissedTips.has(tipId)),
      }}
    >
      {children}
    </TourtipContext.Provider>
  );
}

// Hook for using the full context
export function useTourtips() {
  const context = useContext(TourtipContext);
  if (!context) {
    throw new Error("useTourtip must be used within a TourtipProvider");
  }
  return context;
}

// Hook for using a single tip
export function useTourtip(tipId: TourtipId) {
  const { tipStates, triggerTip, isTipDismissed, isTipActive, dismissTip } = useTourtips();
  return {
    tipState: tipStates[tipId],
    trigger: () => triggerTip(tipId),
    isActive: isTipActive(tipId),
    isDismissed: isTipDismissed(tipId),
    dismiss: () => dismissTip(tipId),
  };
}

import { getActiveOrganizationID } from "@/context/ActiveOrganizationProvider";
import axios, { AxiosPromise, AxiosRequestConfig, Method } from "axios";
import { getAuthToken } from "lib/use-auth";
import SSE from "./sse";

// next.js will replace these values at build time, so we can't use destructuring here.
// https://nextjs.org/docs/basic-features/environment-variables
const baseProxyPath = process.env.PROXY_BASE;
const baseAPIPath = process.env.SERVER_API_URI || process.env.NEXT_PUBLIC_API_URI;

// `window` is undefined on the server and defined on the client, so we use it to detect
// which environment we're in
//
// From the client, this should be set to the prefix next.js uses
// to proxy backend requests.
//
// For SSR, it should be set to the URL of the backend API
// since next does not proxy server-side requests
const serviceBasePath = typeof window === "undefined" ? baseAPIPath : baseProxyPath;

const getActiveOrganizationHeader = (): Record<string, string> => {
  if (typeof window === "undefined") {
    return {};
  }
  const activeOrganizationId = getActiveOrganizationID();
  return activeOrganizationId ? { "X-Humanloop-Active-Organization": activeOrganizationId } : {};
};

const request = (method: Method, url: string, data: any = {}, config: AxiosRequestConfig = {}): AxiosPromise<any> => {
  // Always send the latest JWT in the header so we don't
  // have auth'd requests still being sent after logout.
  const token = getAuthToken() || process.env.TEST_AUTH_COOKIE || "";
  const instance = axios.create({
    baseURL: serviceBasePath,
    headers: {
      Authorization: `Bearer ${token}`,
      ...getActiveOrganizationHeader(),
    },
  });
  return instance({ method, url, data, ...config });
};

const get = (url: string, config: AxiosRequestConfig = {}) => {
  return request("GET", url, {}, config);
};

const post = (url: string, data?: any, config: AxiosRequestConfig = {}) => {
  return request("POST", url, data, config);
};

const put = (url: string, data: any, config: AxiosRequestConfig = {}) => {
  return request("PUT", url, data, config);
};

const patch = (url: string, data: any, config: AxiosRequestConfig = {}) => {
  return request("PATCH", url, data, config);
};

const remove = (url: string, config: AxiosRequestConfig = {}) => {
  return request("DELETE", url, {}, config);
};

// Server-Sent Events
const sse = (url: string, method: Method, payload: any = {}): SSE => {
  const token = getAuthToken() || process.env.TEST_AUTH_COOKIE || "";
  const source = new SSE(`${serviceBasePath}${url}`, {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`,
      ...getActiveOrganizationHeader(),
    },
    method,
    payload: JSON.stringify(payload),
  });
  return source;
};

/** SWR fetcher which returns the JSON response
 *
 * Note that we consistently call useSWR with url keys
 * that are arrays e.g. `[`/v4/users/me`, getAuthToken()]`.
 * This is so that the cache is keyed by the auth token, not just the
 * url, so that when the auth token changes, the cache is invalidated.
 *
 * Starting from SWR 2.0, the fetcher is called with the key array, rather than
 * spread props, so we need to handle that here.
 *
 * See: https://swr.vercel.app/docs/arguments#multiple-arguments
 */
const getWithToken = async (keys: [url: string, ..._tokenAndOthers: string[]]) => {
  // To be clear, we don't use the token here (that's done in request() above),
  // but as its in the SWR key, we're given it here too.
  if (typeof keys === "string") {
    // At least for now...
    console.error(`useSWR should be called with key array of [url, token], so that the cache is user-specific. 
    Got '${keys}'.`);
    const url = keys;
    const response = await get(url);
    return response.data;
  } else {
    const [url, ..._tokenAndOthers] = keys;
    const response = await get(url);
    return response.data;
  }
};

export const ApiService = { get, post, put, patch, remove, sse, request, getWithToken };

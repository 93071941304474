import { Organization, Team, UserOrganizationRole, UserTeamRole } from "services/organizations.service";
import { CurrentUser, OrganizationMembership } from "services/users.service";
import * as Yup from "yup";
import { getActiveOrganizationID } from "@/context/ActiveOrganizationProvider";

export const getUserRoleInTeam = (userId: string, team: Team): UserTeamRole | null => {
  return team.users.find((u) => u.id == userId)?.role || null;
};

export const getUserRoleInOrganization = (userId: string, organization: Organization): UserOrganizationRole | null => {
  return organization.users.find((u) => u.id == userId)?.role || null;
};

export const getDefaultOrganizationMembership = (user: CurrentUser | undefined): OrganizationMembership | undefined => {
  /**
   * Returns the default organization for the user.
   */
  if (!user || user?.organizations?.length === 0) return undefined;
  const defaultOrganization = user.organizations.find((orgUser) => orgUser.is_default_organization);
  if (defaultOrganization) {
    return defaultOrganization;
  } else {
    // If no default organization
    throw new Error(`User ${user.id} does not have a default organization`);
  }
};

export const getActiveOrganizationMembership = (user: CurrentUser | undefined): OrganizationMembership | undefined => {
  /**
   * Returns the OrganizationMembership object for the user.
   * Generally the OrganizationMembership is the user specific context for the organization.
   */

  if (!user || user?.organizations?.length === 0) return undefined;

  // Fetch active organization from session, if it exists
  const activeOrganizationId = getActiveOrganizationID();
  if (activeOrganizationId) {
    const activeOrg = user.organizations.find((orgUser) => orgUser.organization.id == activeOrganizationId);
    return activeOrg;
  } else {
    // If no active organization in session, fetch default organization
    return getDefaultOrganizationMembership(user);
  }
};

export const getUserOrganizations = (user: CurrentUser | undefined): OrganizationMembership[] | undefined => {
  if (!user) return undefined;
  return user.organizations;
};

export const checkCanUserDeploy = (user: CurrentUser | undefined): boolean => {
  // TODO: Generalise to mapping of action to permission
  if (!user) return false;
  const orgMembership = getActiveOrganizationMembership(user);
  return [UserOrganizationRole.admin, UserOrganizationRole.developer].includes(
    orgMembership?.role ?? UserOrganizationRole.admin,
  );
};

const AT_LEAST_ONE_LOWERCASE_LETTER = /[a-z]+/;
const AT_LEAST_ONE_UPPERCASE_LETTER = /[A-Z]+/;

export const PASSWORD_VALIDATION = Yup.string()
  .min(8, "Minimum of 8 characters")
  .matches(AT_LEAST_ONE_LOWERCASE_LETTER, "Must have at least one lowercase letter")
  .matches(AT_LEAST_ONE_UPPERCASE_LETTER, "Must have at least one uppercase letter")
  .required("Required");

import { AxiosPromise } from "axios";
import { getAuthToken } from "lib/use-auth";
import useSWR, { SWRConfiguration } from "swr";
import { ApiService } from "./api.service";
import { BaseOrganization, BaseTeam, UrlIcon, UserOrganizationRole, UserTeamRole } from "./organizations.service";
import { User } from "@/types/app/user";

interface UserTeam {
  role: UserTeamRole;
  team: BaseTeam;
}

export interface OrganizationMembership {
  role: UserOrganizationRole;
  organization: BaseOrganization;
  is_default_organization: boolean;
}

/**
 * User returned by the /me endpoint.
 */
export interface CurrentUser extends User {
  teams: UserTeam[];
  organizations: OrganizationMembership[];
}

interface CreateUserRequest {
  password: string;
  email_address: string;
  full_name?: string;
}

interface CreateUserResponse {
  id: string;
  email_address: string;
  full_name: string | null;
  verified: boolean;
}

interface UpdateUserRequest {
  full_name?: string;
  intent?: Record<string, unknown>;
  ui_state?: Record<string, unknown>;
  default_organization_id?: string;
  // TODO: image etc.
}

interface LimitedOrganization {
  id: string;
  name: string;
  size: number;
  icon: UrlIcon | null;
}

export interface InvitedOrganizations {
  organizations: LimitedOrganization[];
}

export const useCurrentUser = (swrOptions: SWRConfiguration<CurrentUser> = {}) => {
  const { data, error, mutate } = useSWR<CurrentUser>([`/v4/users/me`, getAuthToken()], swrOptions);
  return { user: data, error: error, loading: !data && !error, mutate };
};

export const createUser = (request: CreateUserRequest): AxiosPromise<CreateUserResponse> => {
  return ApiService.post(`/v4/users`, request);
};

export const updateUser = (request: UpdateUserRequest): AxiosPromise<CreateUserResponse> => {
  return ApiService.patch(`/v4/users/me`, request);
};

export const resendVerification = (email: string): AxiosPromise<void> => {
  return ApiService.post(`/v4/users/resend-verification`, { email_address: email });
};

export interface VerifyUserRequest {
  token: string;
  password?: string;
  full_name?: string;
}

export interface VerifyUserResponse {
  user: CreateUserResponse;
  access_token: string;
  token_type: "bearer";
}

export const verifyUser = (request: VerifyUserRequest): AxiosPromise<VerifyUserResponse> => {
  return ApiService.post(`/v4/users/verify`, request);
};

export const useUserInvites = (swrOptions: SWRConfiguration<InvitedOrganizations> = {}) => {
  const { data, error, mutate } = useSWR<InvitedOrganizations>([`/v4/users/invites`, getAuthToken()], swrOptions);
  return { invites: data, error: error, loading: !data && !error, mutate };
};

import { useAuth } from "@/lib/use-auth";
import { getActiveOrganizationMembership } from "@/lib/user";
import { Organization, useOrganization } from "@/services/organizations.service";
import Button from "@components/library/Button";
import CustomHead from "@components/templates/CustomHead";
import ErrorPage from "@components/templates/ErrorPage";
import { useRouter } from "next/router";
import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { KeyedMutator, useSWRConfig } from "swr";
import { useSessionStorage } from "usehooks-ts";

export interface ActiveOrganizationInterface {
  /* The 'slug' field of an organization - pulled out for convenience. */
  slug: string; // Organization slug (string), loading/not set (empty string)
  organization: Organization | null;
  mutate: KeyedMutator<Organization>;
  loading: boolean;
  setActiveOrganization: ({ id, slug }: ActiveOrganizationSessionInterface) => void;
  clearActiveOrganization: () => void;
}

const ACTIVE_ORGANIZATION_SESSION_KEY = "active_organization";

const ActiveOrganizationContext = createContext<ActiveOrganizationInterface | undefined>(undefined);

export const useActiveOrganization = () => {
  const context = useContext(ActiveOrganizationContext);
  if (context === undefined) {
    throw new Error("useActiveOrganization must be used within ActiveOrganizationProvider");
  }
  return context;
};

export interface ActiveOrganizationSessionInterface {
  id: string | null;
  slug: string | null;
}

export const ActiveOrganizationProvider = ({ children }: React.PropsWithChildren<{}>) => {
  /** Provides the active organization for the user.
   *
   * Primarily useful when needing the orgSlug to build links for components in the app.
   *
   * The active organization is the the organiztion that matches the orgSlug in the url.
   * If the user is not logged in, the active organization is null.
   *
   * If the user is logged in, but the orgSlug is not in the url, the active organization is
   * the only organization in the user's list of organizations.
   * (Note this will be modified with multi-org support)
   *
   * The active organization id is stored in session storage and cleared on user logout.
   */

  const [sessionOrganization, setSessionOrganization] = useSessionStorage<ActiveOrganizationSessionInterface | null>(
    ACTIVE_ORGANIZATION_SESSION_KEY,
    null,
  );

  const { user, loading: userLoading, logout } = useAuth();
  const { organization, mutate, loading: organizationLoading } = useOrganization(sessionOrganization?.id);
  const { mutate: mutateSwr } = useSWRConfig();

  const router = useRouter();
  const orgSlug = router.query.orgSlug as string;
  const [orgNotFoundOrAuthorized, setOrgNotFoundOrAuthorized] = useState<boolean>(false);

  useEffect(() => {
    if (!user || userLoading) {
      // Adding in user loading as when the orgSlug is updated for an active organization
      // it wont immediately match, will need to wait for reload
      return;
    }

    if (!orgSlug) {
      // If no org slug is provided in the url then fetch the active organization from the user
      const activeOrganization = getActiveOrganizationMembership(user);
      if (activeOrganization) {
        console.log("Setting active organization from user, no org slug", activeOrganization.organization.id);
        setSessionOrganization({
          id: activeOrganization.organization.id,
          slug: activeOrganization.organization.slug,
        });
      }
      return;
    }

    if (orgSlug === "signup") {
      // If the org slug is signup, then the user is signing up
      // the active organization will be set when they create an organization
      return;
    }

    // Find the matching org from the user
    const activeOrganizationFromSlug = user.organizations.find(({ organization }) => organization.slug === orgSlug);
    if (!activeOrganizationFromSlug && sessionOrganization?.slug && sessionOrganization?.slug !== orgSlug) {
      setOrgNotFoundOrAuthorized(true);
      return;
    }

    // If found a matching org, set the org id in session storage
    // and revalidate the swr cache. This is needed to refetch any data that
    // was loaded before the active organization was set.
    if (activeOrganizationFromSlug && !activeOrganizationFromSlug.is_default_organization) {
      mutateSwr(
        (key) => {
          // Avoid mutating current user.
          if (Array.isArray(key) && key[0].endsWith("/users/me")) {
            return false;
          }
          // Avoid mutating the current organization.
          if (Array.isArray(key) && key[0].includes("/v4/organizations")) {
            return false;
          }
          return true;
        }, // which cache keys are updated
        undefined, // update cache data to `undefined`
        { revalidate: true }, // refetch data after updating, needed for any pages that are currently open.
      );
    }

    setSessionOrganization({
      id: activeOrganizationFromSlug?.organization?.id || null,
      slug: activeOrganizationFromSlug?.organization?.slug || null,
    });
  }, [organizationLoading, setSessionOrganization, user, userLoading, orgSlug, sessionOrganization?.slug, mutateSwr]);

  const setActiveOrganization = useCallback(
    ({ id, slug }: ActiveOrganizationSessionInterface) => {
      setSessionOrganization({
        id,
        slug,
      });
    },
    [setSessionOrganization],
  );

  const clearActiveOrganization = useCallback(() => {
    setSessionOrganization({
      id: null,
      slug: null,
    });
  }, [setSessionOrganization]);

  return (
    <ActiveOrganizationContext.Provider
      value={{
        slug: organization?.slug || "",
        organization: organization || null,
        mutate,
        loading: organizationLoading,
        setActiveOrganization,
        clearActiveOrganization,
      }}
    >
      {orgNotFoundOrAuthorized ? (
        <div>
          <CustomHead pageName="Not Found" />
          <ErrorPage
            title={"Not found"}
            statusCode={404}
            text={
              <>
                You may not have access. You are logged in as{" "}
                <div className="inline-flex items-center gap-8">
                  <strong>{user?.email_address}</strong>{" "}
                  <Button
                    size={24}
                    onClick={() => {
                      logout();
                    }}
                  >
                    log out
                  </Button>
                </div>
              </>
            }
          />
        </div>
      ) : (
        children
      )}
    </ActiveOrganizationContext.Provider>
  );
};

export const getActiveOrganizationID = (): string | null => {
  /** To directly fetch active organization ID from session storage */
  const activeOrganization = window.sessionStorage.getItem(ACTIVE_ORGANIZATION_SESSION_KEY);
  if (activeOrganization) {
    return JSON.parse(activeOrganization)?.id;
  }
  return null;
};

export const clearActiveOrganizationSessionStorage = () => {
  /** Clear the active organization from session storage, for use when a user logs out */
  window.sessionStorage.removeItem(ACTIVE_ORGANIZATION_SESSION_KEY);
};

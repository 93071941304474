// Inline link component, looks like a text link can be a button or a link.
// https://www.figma.com/file/Ya7mCOIZJp1UdBCH3VxnEL/Components-v2?type=design&node-id=319-2383&mode=design&t=VnbJmwpzEtqD2IqI-11
// This is still WIP. Feel free to extend and improve as needed.

import React from "react";
import { ButtonProps, UnstyledButtonBase } from "./Button";
import { classNames } from "./utils/classNames";

interface InlineLinkProps extends ButtonProps {
  IconLeft?:
    | React.FunctionComponent<React.ComponentProps<"svg">>
    | React.FunctionComponent<React.ComponentProps<"img">>;
  IconRight?:
    | React.FunctionComponent<React.ComponentProps<"svg">>
    | React.FunctionComponent<React.ComponentProps<"img">>;
  iconClasses?: string;
}

export const InlineLink = React.forwardRef(function InlineLinkComponent(
  { IconLeft, IconRight, iconClasses, ...props }: InlineLinkProps,
  ref: React.ForwardedRef<HTMLAnchorElement>,
) {
  const classes = {
    base: "border-none cursor-pointer focus:outline-none items-center",
    normal: "cursor-pointer text-text-secondary-3 hover:text-text-secondary-2 ",
    disabled: "text-text-neutral-6",
    active: "hover:text-text-secondary-3",
  };

  return (
    <UnstyledButtonBase
      ref={ref}
      {...props}
      className={classNames(
        classes.base,
        props.disabled ? classes.disabled : props.active ? classes.active : classes.normal,
        props.className,
      )}
    >
      {/* Narrow nbsp to keep the icon together with the text without too big of a gap. */}
      {props.children}&#8239;
      {IconRight && <IconRight className={classNames("text-text- ml-4 inline-block", iconClasses)} />}
    </UnstyledButtonBase>
  );
});

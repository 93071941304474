import { classNames } from "lib/utils";
import React from "react";
import { isMacOs } from "react-device-detect";
import { CommandIcon } from "./Icons";

export interface KeyboardShortcutProps {
  className?: string;
  children: React.ReactNode;
  styling?: "solid" | "outline";
  shade?: "blue" | "gray";
  size?: 16 | 20 | 24;
  disabled?: boolean;
}

const KeyboardShortcut = ({
  className,
  children,
  styling = "solid",
  shade = "gray",
  size = 20,
  disabled = false,
}: KeyboardShortcutProps) => {
  const classes = {
    base: "inline-flex items-center justify-center capitalize select-none rounded-sm font-bold whitespace-nowrap",
    size: {
      16: "h-16 min-w-[16px] px-[3.5px] text-11-12",
      20: "h-20 min-w-[20px] px-4 text-12-12 pt-1 -mt-1", //hacky padding just to make it quicky look on baseline in center. sorry.
      24: "h-24 min-w-[24px] px-6 text-14-14",
    },
    styling: {
      solid: {
        ["gray"]: disabled
          ? "text-oldgray-500 bg-oldgray-100"
          : "text-oldgray-800 bg-oldgray-100 group-active:bg-oldgray-300",
        ["blue"]: disabled
          ? "text-oldblue-300 bg-oldgray-200"
          : "text-oldblue-600 bg-oldblue-300 group-active:bg-oldblue-200",
      },
      // DECRECATED: This seems to no longer be part of our design library.
      //  Keeping for now as it looks ok and useful to me.
      outline: {
        ["gray"]: "text-oldgray-400 border border-grey-400 group-active:bg-oldgray-300",
        ["blue"]: "text-oldblue-400 border border-oldblue-400 group-active:bg-oldblue-300",
      },
    },
  };

  return (
    <div className={classNames(classes.base, classes.size[size], classes.styling[styling][shade], className)}>
      {children}
    </div>
  );
};

export const ModKey = ({ size, className }: { size?: 11 | 12; className?: string }) =>
  isMacOs ? (
    <CommandIcon
      className={classNames("inline-block shrink-0", size === 11 ? "h-[11px] w-[11px]" : "h-12 w-12", className)}
    />
  ) : (
    <span>Ctrl</span>
  );

export const ShiftKey = `⇧`;

export const CmdEnterShortcut = () => {
  return (
    <>
      <KeyboardShortcut>
        <ModKey className="mx-1 pb-1" />
      </KeyboardShortcut>{" "}
      +<KeyboardShortcut> Enter</KeyboardShortcut>
    </>
  );
};

export default KeyboardShortcut;

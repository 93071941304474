import * as Sentry from "@sentry/nextjs";
import React, { useCallback, useEffect } from "react";
import { AppError } from "./AppError";
import { patchRemoveChildAndInsertBefore } from "./utils";

const PATCH_KEY = "patch-remove-child-and-insert-before";
const PATCH_VALUE = "true";

// Attempt to fix React errors caused by extension/browser shenanigans (#1051)
// * add useEffect call to patch removechild and insertbefore if key is detected
export const AppErrorBoundary = ({ children }: React.PropsWithChildren<Record<string, unknown>>) => {
  const onError = useCallback((error: unknown, componentStack: string | undefined, eventId: string) => {
    const errorString = (error as Error)?.toString();
    if (
      localStorage.getItem(PATCH_KEY) !== PATCH_VALUE &&
      errorString &&
      errorString.includes("NotFoundError") &&
      (errorString.includes("'insertBefore' on 'Node'") || errorString.includes("'removeChild' on 'Node'"))
    ) {
      console.log("Found removeChild/insertBefore error. Setting patch key and refreshing...");
      localStorage.setItem(PATCH_KEY, PATCH_VALUE);
      window.location.reload();
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem(PATCH_KEY) === PATCH_VALUE) {
      console.log("Patching removeChild and insertBefore");
      patchRemoveChildAndInsertBefore();
    }
  }, []);
  return (
    <Sentry.ErrorBoundary
      onError={onError}
      fallback={({ error, resetError }) => {
        return <AppError />;
      }}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

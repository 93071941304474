import Button from "@components/library/Button";
import { MessageUs } from "@components/library/MessageUs";
import { XIcon } from "@heroicons/react/outline";

export const AppError = () => (
  <div className="flex h-full w-full flex-col items-center p-20 text-center">
    <div>
      <XIcon className="h-100 w-100 text-oldgray-700" />
    </div>
    <h1 className="text-24-32 font-bold text-oldgray-700 ">Something went wrong</h1>

    <p className="mt-16 text-14-24 text-oldgray-700 ">
      The error has been logged. If you need immediate help, <br /> or this keeps happening, please <MessageUs />.
    </p>
    <div className="mt-20 flex gap-12">
      <Button size={40} onClick={() => window.location.reload()}>
        Reload
      </Button>
    </div>
  </div>
);

import { useEffect } from "react";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import localizedFormat from "dayjs/plugin/localizedFormat";
import updateLocale from "dayjs/plugin/updateLocale";
import "dayjs/locale/en-gb";

dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(localizedFormat);
dayjs.extend(updateLocale);

// This is the locale we use for DayJS throughout the app.
// We could attempt to detect the browser's local with `dayJsLocaleFromBrowser` but
// we would then have to handle setting our custom relativeTime strings for each locale.
// Also, it seems that "en" and "en-gb" have to be handled separately (and likely the other
// English variants as well). To avoid this, we default to "en-gb" for now.
const GLOBAL_LOCALE = "en-gb";

dayjs.updateLocale(GLOBAL_LOCALE, {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "seconds",
    m: "a min",
    mm: "%d mins",
    h: "an hour",
    hh: "%d hours",
    d: "a day",
    dd: "%d days",
    M: "a month",
    MM: "%d months",
    y: "a year",
    yy: "%d years",
  },
});

const DayjsConfig = () => {
  useEffect(() => {
    const locale = GLOBAL_LOCALE;
    if (locale !== undefined) {
      const loadedLocale = dayjs.locale(locale);
      console.log("Loaded locale", loadedLocale);
    }
  }, []);
  return <></>;
};

export default DayjsConfig;
